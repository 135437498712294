import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { isServer } from '~/utils/platform';

const firebaseConfig = {
  apiKey: 'AIzaSyB9o1Gnt7g-521FhqIjnh-h6SOt6BG0070',
  authDomain: 'florencecard-ab3ab.firebaseapp.com',
  projectId: 'florencecard-ab3ab',
  storageBucket: 'florencecard-ab3ab.appspot.com',
  messagingSenderId: '570036758994',
  appId: '1:570036758994:web:79ac9afde681323207400b',
};

if (!isServer()) {
  firebase.initializeApp(firebaseConfig);
  firebase.auth().useDeviceLanguage();
}

export type firebase = typeof firebase;

export async function getCurrentUserToken() {
  const currentUser = firebase.auth().currentUser;

  if (currentUser == null) {
    return null;
  }

  try {
    return await currentUser.getIdToken();
  } catch {
    return null;
  }
}

export async function signOut() {
  return await firebase.auth().signOut();
}

export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;

export interface FirebaseError {
  code: string;
  message: string;
}

export const isFirebaseError = (error: unknown): error is FirebaseError =>
  error != null && (error as FirebaseError).code != null;

export const queryFirebaseErrorCode = (error: unknown) =>
  isFirebaseError(error) ? error.code : undefined;

export enum FirebaseAuthErrorCodes {
  InvalidPhoneNumber = 'auth/invalid-phone-number',
  InvalidVerificationCode = 'auth/invalid-verification-code',
}

const FirebaseContext = createContext<typeof firebase | undefined>(undefined);
FirebaseContext.displayName = 'FirebaseContext';

interface FirebaseProviderProps {
  children?: ReactNode;
}

export function FirebaseProvider({ children }: FirebaseProviderProps) {
  const [value, setValue] = useState<typeof firebase>();

  useEffect(() => {
    setValue(firebase);
  }, []);

  return <FirebaseContext.Provider value={value}>{children}</FirebaseContext.Provider>;
}

export function useFirebase() {
  return useContext(FirebaseContext);
}
